import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact Us' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Let's Connect!"
          subheader='Have a question about our guides, reviews, or recipes?
          Are you interested in joining our team of independent pressure cooking enthusiasts?
          Please use the contact form below for all your questions.
          We will be happy to help you in any way we can.'
        />
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
