import React from 'react'
import {Card, Text, Flex, Heading} from 'theme-ui'
// import { FaPhone, FaEnvelope, FaLocationArrow } from 'react-icons/fa'
import Section from '@components/Section'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'
import Divider from "@components/Divider";

const ContactInfo = () => {
  const { phone, address, email } = useSiteMetadata()

  return (
    <Section aside title='Partnerships & Advertising'>
      <Card variant='paper'>
        <Text variant='p'>We do not accept inbound advertising or paid reviews at this time.
            Our total commitment is to producing excellent, unbiased content for our followers.</Text>
      </Card>
        <>
     <Divider />
      <Flex>
        <Heading variant='h2' >
            Press
        </Heading>
      </Flex>
      <Divider />
    </>
      <Card variant='paper'>
        <Text variant='p'>Our editorial team loves nothing more than talking about their Insta-obsession to the media.
        If you are interested in a quote or interview with an editor or have a general press inquiry, please email press[@]instapots.com.
        </Text>
      </Card>
    </Section>
  )
}

export default ContactInfo
